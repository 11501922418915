
import {
    defineComponent,
    SetupContext,
    onMounted,
    ref,
    watch,
    getCurrentInstance,
    computed,
    PropType,
    onBeforeUnmount,
    onActivated,
    onDeactivated,
    defineAsyncComponent,
} from 'vue';
import type Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';
import Toast from 'vant/lib/toast';
import cookies from 'js-cookie';
import 'swiper/css/swiper.css';
import { Feed, UserComment } from '@/api/feed';
import { RequestSource } from '@/api/base';
import { MobilePageSource } from '@/constants/base';
import { sendLog } from '@/common/radar';
import { getLlsidFromExptag } from '@/common/common-params';
import { VideoRef } from '../../type';
import { FeedPageEnum, FeedListOriginType } from '@/store/modules/feed';
import { UserInfoByLogin } from '@/api/user';
import { useWebToApp, OpenPageStatus } from '~/hooks/useWebToApp';
import { installPwaFun, isContinue, continueMap } from '@/common/pwa';
import device from '@/common/device-info';
import { CrawlersApiRequest } from '@/common/crawlers';

const FeedPageEnumPageSourceMap: Record<FeedPageEnum, MobilePageSource> = {
    [FeedPageEnum.Foryou]: MobilePageSource.PwaForyou,
    [FeedPageEnum.VideoDetailShare]: MobilePageSource.PwaVideoDetailShare,
    [FeedPageEnum.PwaShareNew]: MobilePageSource.PwaVideoDetailShare,
    [FeedPageEnum.VideoDetail]: MobilePageSource.PwaVideoDetail,
    [FeedPageEnum.DiscoverSearch]: MobilePageSource.PwaDiscoverSearch,
};

const FeedPageEnumRequestSourceMap: Record<FeedPageEnum, RequestSource> = {
    [FeedPageEnum.Foryou]: RequestSource.REQUEST_SOURCE_PWA_NEW_FORYOU,
    [FeedPageEnum.VideoDetailShare]: RequestSource.REQUEST_SOURCE_PWA_VIDEO_DETAIL,
    [FeedPageEnum.PwaShareNew]: RequestSource.REQUEST_SOURCE_PWA_NEW_FORYOU,
    [FeedPageEnum.VideoDetail]: RequestSource.REQUEST_SOURCE_PWA_VIDEO_DETAIL,
    [FeedPageEnum.DiscoverSearch]: RequestSource.REQUEST_SOURCE_PWA_DISCOVER_SEARCH,
};

const FeedList = defineComponent({
    components: {
        VueSwiper: defineAsyncComponent(() => import('vue-awesome-swiper').then(res => res.Swiper)),
        VueSwiperSlide: defineAsyncComponent(() => import('vue-awesome-swiper').then(res => res.SwiperSlide)),
        LuminaAD: defineAsyncComponent(() => import('../lumina-ad/index.vue')),
        AIChatBigCard: defineAsyncComponent(() => import('../ai-chat-card/index.vue')),
        CommentList: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/comment-list" */ '../comment/index.vue')),
        ActionBar: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/action-bar" */ '../action-bar/index.vue')),
        VideoInfo: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/video-info" */ '../video-info/index.vue')),
        AiAdGuideCard: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/ai-ad-guide-card" */ '../ai-ad-guide-card/index.vue')),
        AiAdGuideCardMark: defineAsyncComponent(
            () => import(/* webpackChunkName: "pwa-share-new/ai-ad-guide-card/mark" */ '../ai-ad-guide-card/mark.vue')
        ),
    },
    props: {
        feedPage: {
            type: String as PropType<FeedPageEnum> | undefined,
            default: FeedPageEnum.PwaShareNew,
        },
        gonnaSlideToAd: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onHideSwipeGuide', 'onSlideFeedTo'],
    setup(props, ctx: SetupContext<Array<'onFeedChange' | 'onHideSwipeGuide' | 'onSlideFeedTo' | 'onChangeLoginPopupVisible'>>) {
        const { proxy } = getCurrentInstance() || {};
        const { isIOS = false } = device();
        const startTime = +new Date();
        const { checkIfInstalled, installPwa } = useWebToApp();
        /**
         * @zh 当前组件是否活跃，结合 document visibility 使用
         */
        const isComponentActive = ref(true);
        const isPlaying = ref(false);
        const isLoading = ref(false);
        const isFetching = ref(false);
        const swiperRef = ref<{ $swiper?: Swiper }>();
        const videoRefs = ref<Array<VideoRef>>([]);
        /**
         * @zh 是否首次拉 feed list 接口
         */
        const isFirstFetchFeed = ref(true);
        /**
         * @zh 当前播放的 feed Index
         */
        const currentIndex = ref(0);
        /**
         * @zh video 状态为 canplay 的 feed Index
         * @zh 状态到达 canplay 认为可播放，可继续预加载下一个 feed
         */
        const canPlayIndex = ref(0);

        const toIndex = ref(0);

        const commentListVisible = ref(false);
        const commentList = ref<Array<UserComment>>([]);

        const isDeferredPromptInstalled = computed(() => {
            return !!proxy?.$store?.state?.deferredPromptInstalled;
        });

        const feedListPreLoadParams = computed(() => {
            const { FetchFeedListDefaultCount, HoldPreVideoCount, NextFeedListMinCount, NextPreLoadVideoCount } =
                proxy?.$store?.state?.abTestModule?.abTestParams || {};

            return {
                FetchFeedListDefaultCount,
                HoldPreVideoCount,
                NextFeedListMinCount,
                NextPreLoadVideoCount,
            };
        });

        const feedList = computed<Feed[]>(() => {
            const { feedListOrigin } = proxy?.$store?.state?.feedMoudle || {};
            return feedListOrigin?.[props?.feedPage] || [];
        });

        const isMuted = computed<Feed[]>(() => {
            const { isMuted } = proxy?.$store?.state?.feedMoudle || {};
            return isMuted;
        });

        const userInfoByLogin = computed<UserInfoByLogin>(() => {
            const userInfoByLogin = (proxy?.$store?.state?.userModule?.userInfo || {}) as UserInfoByLogin;
            return userInfoByLogin;
        });

        const urlParams = computed<{ photoId?: string }>(() => proxy?.$route?.params || {});

        const currentFeed = computed(() => {
            const feed = feedList.value[currentIndex.value];
            proxy?.$store?.commit('feedMoudle/setCurrentFeed', feed);
            return feed;
        });

        const currentFeedPhotoId = computed(() => {
            const feed = currentFeed.value;
            return feed?.photo_id_str;
        });

        const handleChangeLoginPopupVisible = (visible?: boolean) => {
            ctx.emit('onChangeLoginPopupVisible', !!visible);
        };

        const onGoogleAdLoad = (index: number) => {
            if (index >= feedList.value.length) {
                return;
            }
            const feed = feedList.value[index];
            if (feed) {
                feed.google_ad = false;
                ctx.emit('onSlideFeedTo', {
                    feed,
                    toIndex: currentIndex.value,
                });
            }
        };
        const onLuminaAdLoadFailed = (index: number) => {
            if (index >= feedList.value.length) {
                return;
            }
            const feed = feedList.value[index];
            if (feed) {
                feed.lumina_ad = false;
                ctx.emit('onSlideFeedTo', {
                    feed,
                    toIndex: currentIndex.value,
                });
            }
        };

        const handleCommentListClose = () => {
            commentListVisible.value = false;
        };

        const handleCommentListShow = () => {
            commentListVisible.value = true;
        };

        const googleStyle = computed(() => {
            if (proxy?.$store?.state?.startup?.ads_open_tough_form_time) {
                return Number(proxy?.$store.state.startup.ads_open_tough_form_time);
            }
            return 0;
        });

        /**
         * @zh 兼容 vue template 不支持 ts 类型标注的写法
         * @example ts build error: <div :ref="(el: HTMLDivElement) => {}"></div>
         */
        const setVideoRefIndex = (index: number) => {
            const setVideoRef = (videoElement: VideoRef) => {
                if (!videoElement || typeof index !== 'number' || !videoRefs.value) {
                    return;
                }
                videoRefs.value[index] = videoElement;
            };

            return setVideoRef;
        };

        const handleChangePlayingStatus = (playStatus: boolean) => {
            isPlaying.value = playStatus;
        };

        const handleClickVideo = (
            index: number,
            options?: {
                clickToPlay?: boolean;
                clickToPause?: boolean;
            }
        ) => {
            const { clickToPlay, clickToPause } = options || {};
            const videoRef = videoRefs?.value?.[index];
            const is_lumina_ad = feedList.value?.[currentIndex.value]?.lumina_ad;
            const handleClickToPause = () => {
                videoRef?.pause?.();
                handleChangePlayingStatus(false);
            };
            const handleClickToPlay = () => {
                if (is_lumina_ad) return;
                videoRef?.play?.();
                handleChangePlayingStatus(true);
            };

            if (clickToPause) {
                handleClickToPause();
                return;
            }
            if (clickToPlay) {
                handleClickToPlay();
                return;
            }
            if (isPlaying.value) {
                handleClickToPause();
                return;
            }
            handleClickToPlay();
        };

        const handleVideoPlaying = () => {
            isLoading.value = false;
        };

        const handleVideoWaiting = () => {
            isLoading.value = true;
        };

        const handleVideoCanPlay = (index: number) => {
            canPlayIndex.value = Math.min(
                Math.max(index + 1, canPlayIndex.value),
                currentIndex.value + feedListPreLoadParams.value.NextPreLoadVideoCount
            );
        };

        /**
         * @zh 播放完自动切到下一个
         */
        const handleVideoEnd = () => {
            const swiperInstance = swiperRef?.value?.$swiper;
            const slideNextIndex = Math.min(currentIndex.value + 1, (feedList.value || []).length);
            swiperInstance?.slideTo?.(slideNextIndex); // 触发 slideChange
        };

        /**
         * @zh 埋点链接：https://data-track-sgp.corp.kuaishou.com/?#/group/list?appName=TRINITY&model.currentPage=1&model.pageSize=10&groupId=8616&model.pageType=0&model.bizName=GENERAL&tagModel.currentPage=1&tagModel.pageSize=10&viewMode=SIMPLE
         */
        const sendCurrentVideoPlayTimeLog = () => {
            const videoRef = videoRefs?.value?.[currentIndex.value];
            const playTime = videoRef?.getCurrentTime?.() || 0;
            const duration = videoRef?.getDuration?.() || 0;
            const feedInfo = feedList?.value?.[currentIndex.value];
            const shouldSendLog = playTime > 0 && duration > 0 && feedInfo?.photo_id_str;

            const { user, userId, user_id } = userInfoByLogin.value || {};
            const uid = userId || user_id || user?.user_id || cookies.get('userId');

            if (!shouldSendLog) {
                return;
            }
            sendLog({
                type: 'click',
                name: 'PLAY_TIME_MOBILE',
                value: {
                    user_id: uid,
                    kwai_id: user?.kwai_id,
                    author_id: feedInfo?.user_id_str || feedInfo?.user_id,
                    author_kwai_id: feedInfo?.kwai_id,
                    photo_id: feedInfo?.photo_id_str,
                    play_time: `${Math.ceil(playTime) * 1000}`,
                    photo_time: `${Math.ceil(duration) * 1000}`,
                    if_error: 0,
                    exp_tag: feedInfo?.exp_tag,
                    llsid: getLlsidFromExptag(feedInfo?.exp_tag),
                },
            });
        };

        /**
         * @zh 上下滑动切换
         */
        const handleSlideChange = () => {
            sendCurrentVideoPlayTimeLog();
            // 滑动后分享按钮动效失效
            proxy?.$store.commit('setHasSwap', true);
            try {
                const videoRef = videoRefs?.value?.[currentIndex.value];
                videoRef?.pause?.();

                const swiperInstance = swiperRef?.value?.$swiper;
                const newCurrentIndex = swiperInstance?.realIndex || 0;
                const newVideoRef = videoRefs?.value?.[newCurrentIndex];
                newVideoRef?.setCurrentTime?.(0);

                const abortIndex = newCurrentIndex - feedListPreLoadParams.value.HoldPreVideoCount - 1;
                const abortVideo = videoRefs?.value?.[abortIndex];
                abortVideo?.abortRequest?.();

                currentIndex.value = swiperInstance?.realIndex || 0;
                const feed = feedList.value[currentIndex.value];
                if (feed.lumina_ad) {
                    newVideoRef?.pause?.();
                    isLoading.value = false;
                } else {
                    newVideoRef?.play?.();
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        };

        /**
         * @zh 带上 photo_id_str 拉接口，第一个视频即为此 id 对应的视频
         * @zh 首次拉 feed list 接口，则带上分享过来的 id
         * @zh 非首次拉 feed list 接口不带分享过来的 id，否则接口返回第一个视频一直是分享过来的视频
         */
        const fetchFeedList = async (params?: { isFirstFetch?: boolean; count?: number }): Promise<Feed[]> => {
            const { isFirstFetch = false, count = feedListPreLoadParams.value.FetchFeedListDefaultCount } = params || {};

            if (isFetching.value) {
                return [];
            }

            const commonParams = {
                count,
                mobile: true,
            };
            const requestSource = FeedPageEnumRequestSourceMap[props.feedPage] || RequestSource.REQUEST_SOURCE_PWA_SHARE_SLIDE;
            const photoIdStr = isFirstFetch ? urlParams?.value?.photoId : undefined;

            function getRequestAPIAndParams() {
                if (props.feedPage === FeedPageEnum.DiscoverSearch) {
                    return {
                        getFeedList: CrawlersApiRequest.getDiscoverSearchFeedList.bind(CrawlersApiRequest),
                        params: {
                            ...commonParams,
                            requestSource,
                            photoIdStr,
                            url: window.location.href,
                            // 不论 node 层请求是否失败，这里只请求 feed list，不需要其他数据
                            onlyTitle: false,
                            needLdJson: false,
                            needTdk: false,
                            crawler: proxy?.$store?.state?.startup?.crawler,
                        },
                    };
                }
                return {
                    getFeedList: CrawlersApiRequest.getFeedList.bind(CrawlersApiRequest),
                    params: {
                        ...commonParams,
                        request_source: requestSource,
                        photo_id_str: photoIdStr,
                        crawler: proxy?.$store?.state?.startup?.crawler,
                    },
                };
            }

            const { getFeedList, params: feedListParams } = getRequestAPIAndParams();

            isFirstFetchFeed.value = false;
            isFetching.value = true;
            const res = await getFeedList(feedListParams as any);
            isFetching.value = false;

            if (res?.photoNotFound) {
                Toast({
                    message: res?.error_msg || proxy?.$root?.$t?.('fail_toast'),
                    duration: 2500,
                    className: 'van-toast-high-z-index',
                });
            }

            /**
             * @zh 首拉 photo_id_str 对应的视频可能不存在，需要去掉 photo_id_str 重新拉一次接口
             */
            const retry = isFirstFetch && (res?.feeds || []).length === 0;
            if (retry && (res?.feeds || []).length === 0) {
                const retryFeedList = await fetchFeedList({ isFirstFetch: false });

                return retryFeedList || [];
            }
            return res?.feeds || [];
        };

        const setFetchListToStore = (feedListOrigin: FeedListOriginType) => {
            // proxy?.$store?.commit?.('feedMoudle/setFeedList', feedListOrigin);
            proxy?.$store?.dispatch?.('feedMoudle/dispatchSetFeedList', feedListOrigin);
            // proxy?.$store?.commit?.('feedMoudle/insertLuminaADSenseFeed', { newFeedOrigin: feedListOrigin, startup: proxy.$store?.state?.startup });
            proxy?.$store?.commit?.('feedMoudle/insertAIChatCardToFeed', { newFeedOrigin: feedListOrigin, startup: proxy.$store?.state?.startup });
        };

        const swiperOptions: SwiperOptions = {
            direction: 'vertical',
            on: {
                slideChangeTransitionEnd: handleSlideChange,
                slidePrevTransitionStart: () => {
                    toIndex.value = Math.max(currentIndex.value - 1, 0);
                    const toFeed = feedList.value?.[toIndex.value];
                    ctx.emit('onSlideFeedTo', {
                        feed: toFeed,
                        toIndex: toIndex.value,
                    });
                },
                slideNextTransitionStart: () => {
                    toIndex.value = currentIndex.value + 1 >= 0 ? currentIndex.value + 1 : 0;
                    const toFeed = feedList.value?.[toIndex.value];
                    ctx.emit('onSlideFeedTo', {
                        feed: toFeed,
                        toIndex: toIndex.value,
                    });
                    const indexNextToIndex = toIndex.value + 1;
                    const feedNextToFeed = feedList.value?.[indexNextToIndex];
                    if (feedNextToFeed?.lumina_ad) {
                        const lumina_index = `adsbyluminaid-${indexNextToIndex}`;
                        const targetNode = document.getElementById(lumina_index) as Element;
                        if (targetNode) {
                            const data_ad_status = targetNode.getAttribute('data-ad-status');
                            if (feedNextToFeed && data_ad_status !== 'filled') {
                                feedNextToFeed.lumina_ad = false;
                            }
                        }
                    }
                },
                reachEnd: async () => {
                    const newFeedList = await fetchFeedList({ isFirstFetch: isFirstFetchFeed.value });
                    setFetchListToStore({
                        [props.feedPage]: newFeedList || [],
                    });
                },
                // 手动滑动
                touchEnd: async () => {
                    // 是否命中pwa不限次数 上下滑实验
                    const {
                        install_pwa_touch = false,
                        if_open_guide_to_desktop,
                        open_guide_seconds = 5,
                    } = proxy?.$store?.state?.abTestModule?.abTestParams;
                    const bucket = proxy?.$store?.state?.startup?.bucket;

                    const isOk = if_open_guide_to_desktop && !isIOS && bucket === 'br';

                    if (!isOk) return;

                    // 看是否到时间
                    const nowTime = +new Date();
                    if (nowTime - startTime < Number(open_guide_seconds) * 1000) {
                        return;
                    }

                    // 命中实验 & 并且未安装
                    if (install_pwa_touch) {
                        const status = checkIfInstalled();
                        // 未安装
                        if (status === OpenPageStatus.noPwaInWeb) {
                            // installPwaFun 内部有频率检测
                            await installPwaFun(installPwa, 'agree_interaction');
                        }
                        if (status === OpenPageStatus.hasPwaInWeb) {
                            const res = await isContinue('openPwa');
                            if (res === continueMap.first) {
                                // 使用pwa 打开
                                (proxy as any).$bus?.emit('openWithPwaDiadog');
                            }
                        }
                    }
                },
            },
        };

        const handleVisibilityChange = () => {
            if (document.hidden || !isComponentActive.value) {
                isPlaying.value = false;
                handleClickVideo(currentIndex.value, { clickToPause: true });
                return;
            }
            handleClickVideo(currentIndex.value, { clickToPlay: true });
        };

        onMounted(async () => {
            // if (!hasServerFetchedData.value) {
            //     // 没有在 node 层请求过数据的话，清空列表
            //     proxy?.$store?.commit?.('feedMoudle/resetFeedList', props.feedPage);
            // }

            if (!proxy?.$store?.state?.feedMoudle?.feedListOrigin?.[props.feedPage]?.length) {
                // store 中没有数据时，拉取新数据
                const newFeedList = await fetchFeedList({ isFirstFetch: isFirstFetchFeed.value });
                setFetchListToStore({
                    [props.feedPage]: newFeedList || [],
                });
            }

            ctx.emit('onFeedChange', {
                feed: proxy?.$store?.state?.feedMoudle?.feedListOrigin?.[props.feedPage]?.[0],
                videoRef: videoRefs.value?.[0],
                newIndex: 0,
            });
            document.addEventListener('visibilitychange', handleVisibilityChange);
        });

        onBeforeUnmount(() => {
            swiperRef?.value?.$swiper?.destroy?.(true, true);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        });

        onActivated(() => {
            isComponentActive.value = true;
            handleClickVideo(currentIndex.value, { clickToPlay: true });
        });

        onDeactivated(() => {
            isComponentActive.value = false;
            handleClickVideo(currentIndex.value, { clickToPause: true });
        });

        watch(
            () => currentIndex.value,
            async newValue => {
                const feedListLength = feedList.value.length;
                const shouldFetchNewList = feedListLength - newValue <= feedListPreLoadParams.value.NextFeedListMinCount;
                if (shouldFetchNewList) {
                    const newFeedList = await fetchFeedList({
                        isFirstFetch: false,
                    });
                    setFetchListToStore({
                        [props.feedPage]: newFeedList || [],
                    });
                }

                canPlayIndex.value = Math.max(newValue, canPlayIndex.value);
                const newFeedInfo = feedList.value?.[newValue];
                const newVideoRef = videoRefs?.value?.[newValue];
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('onFeedChange', {
                    feed: newFeedInfo,
                    videoRef: newVideoRef,
                    newIndex: newValue,
                });

                const feed = feedList.value[newValue];
                if (feed.google_ad) {
                    const swiperInstance = swiperRef?.value?.$swiper;
                    if (swiperInstance?.allowSlideNext !== undefined) {
                        swiperInstance.allowSlideNext = false;
                    }
                    let ads_open_tough_form_time = 0;
                    if (proxy?.$store?.state?.startup?.ads_open_tough_form_time) {
                        ads_open_tough_form_time = Number(proxy?.$store.state.startup.ads_open_tough_form_time); // number
                    }
                    setTimeout(() => {
                        ctx.emit('onHideSwipeGuide', false);
                        if (swiperInstance?.allowSlideNext !== undefined) {
                            swiperInstance.allowSlideNext = true;
                        }
                    }, ads_open_tough_form_time * 1000);
                } else {
                    ctx.emit('onHideSwipeGuide', true);
                }
            }
        );

        return {
            // constants
            feedListPreLoadParams,
            swiperOptions,
            feedList,
            isLoading,
            currentIndex,
            canPlayIndex,
            bizData: { pageSource: FeedPageEnumPageSourceMap[props.feedPage || FeedPageEnum.PwaShareNew] },
            isMuted,
            googleStyle,
            commentListVisible,
            commentList,
            isDeferredPromptInstalled,
            // element refs
            videoRefs,
            swiperRef,
            currentFeed,
            currentFeedPhotoId,
            // handler
            onGoogleAdLoad,
            onLuminaAdLoadFailed,
            handleClickVideo,
            handleVideoPlaying,
            handleVideoWaiting,
            handleVideoEnd,
            handleVideoCanPlay,
            setVideoRefIndex,
            handleChangeLoginPopupVisible,
            handleCommentListClose,
            handleCommentListShow,
        };
    },
});

export default FeedList;
