var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"feed-list-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading && !_vm.currentFeed.lumina_ad),expression:"isLoading && !currentFeed.lumina_ad"}],staticClass:"wave-loader"}),_vm._v(" "),((_vm.feedList || []).length !== 0)?_c('VueSwiper',{ref:"swiperRef",staticClass:"swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.feedList || []),function(feed,index){return _c('VueSwiperSlide',{key:`${feed.photo_id_str}-${feed?.main_mv_urls?.find?.(mv => !!mv?.url)?.url || ''}`,staticClass:"swiper-slider"},[(!(_vm.canPlayIndex < index || _vm.currentIndex - index > _vm.feedListPreLoadParams.HoldPreVideoCount))?[_c('a-video-player',{directives:[{name:"show",rawName:"v-show",value:(!feed.lumina_ad && !feed?.ai_chat_card),expression:"!feed.lumina_ad && !feed?.ai_chat_card"}],ref:_vm.setVideoRefIndex(index),refInFor:true,staticClass:"video-player-container",attrs:{"id":feed.photo_id_str || index,"biz-data":{
                        ..._vm.bizData,
                        source_type: feed?.source_type,
                    },"poster":feed?.cover_first_frame_urls?.find?.(cover => !!cover?.url)?.url,"ref-key":`${feed.photo_id_str || index}`,"loop":false,"preload":"auto","autoplay":index === 0 && _vm.currentIndex === 0,"playsinline":true,"muted":_vm.isMuted,"src":feed?.transcode_manifest_info || feed?.main_mv_urls?.find?.(mv => !!mv?.url)?.url || '',"photo-id":feed?.photo_id_str || ''},on:{"click":() => _vm.handleClickVideo(index),"playing":_vm.handleVideoPlaying,"waiting":_vm.handleVideoWaiting,"error":_vm.handleVideoWaiting,"ended":_vm.handleVideoEnd,"canplay":() => _vm.handleVideoCanPlay(index)}}),_vm._v(" "),(feed.ai_ad_guide && _vm.isDeferredPromptInstalled && !feed?.lumina_ad && !_vm.gonnaSlideToAd && !feed?.ai_chat_card)?_c('client-only',[_c('AiAdGuideCardMark'),_vm._v(" "),_c('AiAdGuideCard',{staticClass:"ai-ad-guide-card",attrs:{"feed":feed,"isCurrentFeed":_vm.currentIndex === index}})],1):_vm._e(),_vm._v(" "),(
                        (!!feed && !feed.ai_ad_guide && !feed?.lumina_ad && !_vm.gonnaSlideToAd && !feed?.ai_chat_card) ||
                        (feed.ai_ad_guide && !_vm.isDeferredPromptInstalled && !feed?.ai_chat_card)
                    )?_c('VideoInfo',{staticClass:"video-info",attrs:{"photo-id":_vm.currentFeed?.photo_id_str || '',"feed":feed}}):_vm._e(),_vm._v(" "),(!!feed && !feed?.ai_chat_card)?_c('ActionBar',{staticClass:"action-bar",class:{ 'pointer-events-none': feed?.lumina_ad },attrs:{"photo-id":feed?.photo_id_str || '',"video-ref":_vm.videoRefs[index],"current-index":index,"feed":feed,"isAd":feed?.lumina_ad,"feed-page":_vm.feedPage},on:{"onClickComment":_vm.handleCommentListShow,"onChangeLoginPopupVisible":_vm.handleChangeLoginPopupVisible}}):_vm._e(),_vm._v(" "),(feed.ai_chat_card)?_c('AIChatBigCard',{staticClass:"ai-chat-big-card",attrs:{"index":index}}):_vm._e(),_vm._v(" "),_c('client-only',[(feed.lumina_ad && !feed?.ai_chat_card)?_c('LuminaAD',{staticClass:"lumina-ad",attrs:{"index":index},on:{"onLuminaAdLoadFailed":_vm.onLuminaAdLoadFailed}}):_vm._e()],1)]:_vm._e()],2)}),1):_vm._e(),_vm._v(" "),_c('CommentList',{attrs:{"photoId":_vm.currentFeedPhotoId,"visibile":_vm.commentListVisible},on:{"onClose":_vm.handleCommentListClose}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }